html {
  height: 100%;
  overflow-x: hidden;
}
.container-fluid {
  min-height: calc(100vh - 564px) !important;
  margin:0 auto;
  position:relative;
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 26px;
  color: #141414;
  overflow-x: hidden;
}

@media (max-width:425px) {
  body{
    width: 100.2%;
  }
}

h2, h3{
  margin: 25px 0;
  font-family: "Oswald",sans-serif;
  font-weight: 500;
  letter-spacing: .02em;
  text-transform: uppercase;
  color: #000;
}

h3{
  font-size: 25px;
  line-height: 33px;
}

p {
  margin: 20px 0;
}

.empty_space{
  margin: 0 !important;
  height: 30px;
}

a{
  text-decoration: none;
}

.b-btn{
  display: inline-block;
  position: relative;
  width: auto;
  outline: 0;
  font-family: "Oswald",sans-serif;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: .15em;
  font-weight: 600;
  text-transform: uppercase;
  box-sizing: border-box;
  margin: 0;
  -webkit-transition: color .3s cubic-bezier(.61,.66,.4,1.14),background-color .3s cubic-bezier(.61,.66,.4,1.14),border-color .3s cubic-bezier(.61,.66,.4,1.14);
  -moz-transition: color .3s cubic-bezier(.61,.66,.4,1.14),background-color .3s cubic-bezier(.61,.66,.4,1.14),border-color .3s cubic-bezier(.61,.66,.4,1.14);
  transition: color .3s cubic-bezier(.61,.66,.4,1.14),background-color .3s cubic-bezier(.61,.66,.4,1.14),border-color .3s cubic-bezier(.61,.66,.4,1.14);
  padding: 15px 34px;
  cursor: pointer;
}


.stop-scrolling {
  height: 100%;
  overflow: hidden;
}